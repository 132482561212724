@charset "utf-8";
/* CSS Document */

.l-wrap {
	width: 1100px !important;
	margin: 0 auto;
}
.l-header,
.l-footer {
	display: none !important;
}
.l-main {
	padding-top: 0 !important;
}
.m-sticky {
	display: none !important;
}